var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tabbar_wrap",style:({
    // '--tabbarBG': verConfig.tabbar.background || '#fff',
    // '--pageBG': verConfig.pagesBG || '#f5f5f5',
  })},[_c('div',{staticClass:"page_placeholder"},[_c('router-view')],1),_c('keep-alive',[_c('Tabbar',{attrs:{"active-color":'var(--tarbarActiveColor)' || _vm.verConfig.theme,"inactive-color":"var(--tarbarColor)","route":"","fixed":"","placeholder":"","safe-area-inset-bottom":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},_vm._l((_vm.verConfig.tabbar.pages),function(item,index){return _c('TabbarItem',{key:index,class:{ special: item.isSpecial },attrs:{"name":index,"to":`/tabbar/${item.pagePath}`},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [(_vm.verConfig.tabbar.type === 'image')?_c('img',{attrs:{"src":props.active ? item.selectedIconPath : item.iconPath,"alt":""}}):_c('i',{staticClass:"iconfont",class:{
              icon_active: props.active,
              [item.selectedIconPath]: props.active && item.selectedIconPath,
              [item.iconPath]: !props.active || !item.selectedIconPath
            }})]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(item.text)))])])}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }